<template>
    <div class="timing-hall-total">
        <timing-hall-settings class="timing-hall-total__settings" />
        <spinner v-if="status === 'loading'" class="timing-hall-total__spinner" />
        <error-message v-else-if="status === 'error'" class="timing-hall-total__error-message">
            Не удалось загрузить данные отчета "Время. Зал".
        </error-message>
        <p v-if="status === 'no-restaurants'">Выберите хотя бы один ресторан.</p>
        <section v-else-if="status === 'success'" class="timing-hall-total__data">
            <h2 class="timing-hall-total__data-heading sr-only">Данные отчета "Время. Зал"</h2>
            <timing-hall-base-table v-if="modeId === 'base'"
                :columns="columns"
                :totalRow="baseReportTotal"
                :rows="reportRestaurantsData"
            />
            <template v-else-if="modeId === 'compareOtherPeriod'">
                <p class="timing-hall-total__error" v-if="!compareTimingHall">Выберите период сравнения.</p>
                <template v-else>
                    <timing-hall-compare-table
                        :columns="columns"
                        :reportRow="compareReportTotal"
                        :compareRow="compareTotal"
                    />
                    <timing-hall-compare-table
                        v-for="restaurant in reportRestaurantsData"
                        :key="restaurant.id"
                        :columns="columns"
                        :reportRow="restaurant"
                        :compareRow="findCompareRestaurant(compareRestaurants, restaurant)"
                    />
                </template>
            </template>
        </section>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
    import { daterangeKey, isDaterangeSet, daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import TimingHallSettings from "./common/TimingHallSettings";
    import TimingHallBaseTable from "./common/TimingHallBaseTable";
    import TimingHallCompareTable from "./common/TimingHallCompareTable";

    export default {
        name: "TimingHallTotal",
        components: {
            TimingHallSettings,
            TimingHallBaseTable,
            TimingHallCompareTable,
        },
        props: {},
        data() {
            return {
                status: "loading",
            };
        },
        computed: {
            ...mapState({
                reportDaterange: state => state.report.daterange,
                reportRestaurants: state => state.report.restaurants,
                compareDaterange: state => state.timingHall.compareDaterange,
                modeId: state => state.timingHall.modeId,
                sorting: state => state.timingHall.sorting,
                groupKitchenDepartments: state => state.timingHall.groupKitchenDepartments,
                data: state => state.timingHall.data.total,
            }),
            ...mapGetters([
                "userRestaurantsList",
                "reportRestaurantsKey",
                "reportKey",
            ]),
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }

                return dateranges;
            },
            requiredHallTimings() {
                return this.requiredDateranges.map(daterange => this.data[`${daterangeKey(daterange)}-${this.reportRestaurantsKey}`]);
            },
            reportTimingHall() {
                return this.data?.[this.reportKey];
            },
            reportTotal() {
                return this.reportTimingHall?.total;
            },
            baseReportTotal() {
                if (this.userRestaurantsList?.length > 1) {
                    let baseReportTotal = {
                        title: "Все рестораны",
                        bar: this.reportTotal?.bar?.time,
                        time_pay: this.reportTotal?.time_pay,
                        time_order: this.reportTotal?.time_order,
                        // link: {
                        //     to: {
                        //         name: "TimingHallRestaurant",
                        //         params: { id: "total" }
                        //     },
                        //     title: `Смотреть тайминги зала всех ресторанов`
                        // },
                    };

                    if (this.groupKitchenDepartments) {
                        baseReportTotal.kitchen = this.reportTotal?.kitchen?.time;
                    } else {
                        this.reportTotal?.kitchen_departments?.forEach(({ id, time }) => baseReportTotal[id] = time);
                    }

                    return baseReportTotal;
                }
            },
            compareReportTotal() {
                let compareReportTotal ={
                    title: daterangeText(this.reportDaterange),
                    caption: "Все рестораны",
                    bar: this.reportTotal?.bar?.time,
                    time_pay: this.reportTotal?.time_pay,
                    time_order: this.reportTotal?.time_order,
                    // link: {
                    //     to: {
                    //         name: "TimingHallRestaurant",
                    //         params: { id: "total" }
                    //     },
                    //     title: `Смотреть тайминги зала всех ресторанов`
                    // },
                };

                if (this.groupKitchenDepartments) {
                    compareReportTotal.kitchen = this.reportTotal?.kitchen?.time;
                } else {
                    this.reportTotal?.kitchen_departments?.forEach(({ id, time }) => compareReportTotal[id] = time);
                }

                return compareReportTotal;
            },
            reportRestaurantsData() {
                return this.reportTimingHall?.restaurants?.map(restaurant => {
                    let newRestaurant = {
                        id: restaurant.id,
                        title: restaurant.title,
                        bar: restaurant.bar.time,
                        time_pay: restaurant.time_pay,
                        time_order: restaurant.time_order,
                        // link: {
                        //     to: {
                        //         name: "TimingHallRestaurant",
                        //         params: { id: restaurant.id }
                        //     },
                        //     title: `Смотреть тайминги зала ресторана ${restaurant.title}`
                        // },
                    };

                    if (this.modeId === 'compareOtherPeriod') {
                        newRestaurant.title = daterangeText(this.reportDaterange);
                        newRestaurant.caption = restaurant.title;
                    }

                    if (this.groupKitchenDepartments) {
                        newRestaurant.kitchen = restaurant.kitchen.time;
                    } else {
                        restaurant.kitchen_departments.forEach(({ id, time }) => newRestaurant[id] = time);
                    }

                    return newRestaurant;
                })?.sort((restaurant1, restaurant2) => {
                    if (this.sorting.field === "title") {
                        return compareStrings(
                            restaurant1.title,
                            restaurant2.title,
                            this.sorting.direction
                        );
                    }

                    return compareNumbers(
                        restaurant1[this.sorting.field],
                        restaurant2[this.sorting.field],
                        this.sorting.direction
                    );
                });
            },
            compareTimingHall() {
                if (isDaterangeSet(this.compareDaterange)) {
                    return this.data[`${daterangeKey(this.compareDaterange)}-${this.reportRestaurantsKey}`];
                }
            },
            compareTotal() {
                let compareTotal = {
                    title: daterangeText(this.compareDaterange),
                    bar: this.compareTimingHall?.total?.bar?.time,
                    time_pay: this.compareTimingHall?.total?.time_pay,
                    time_order: this.compareTimingHall?.total?.time_order,
                };

                if (this.groupKitchenDepartments) {
                    compareTotal.kitchen = this.compareTimingHall?.total?.kitchen?.time;
                } else {
                    this.compareTimingHall?.total?.kitchen_departments?.forEach(({ id, time }) => compareTotal[id] = time);
                }

                return compareTotal;
            },
            compareRestaurants() {
                return this.compareTimingHall?.restaurants?.map(restaurant => {
                    let newRestaurant = {
                        id: restaurant.id,
                        title: restaurant.title,
                        bar: restaurant.bar.time,
                        time_pay: restaurant.time_pay,
                        time_order: restaurant.time_order,
                    };

                    if (this.groupKitchenDepartments) {
                        newRestaurant.kitchen = restaurant.kitchen.time;
                    } else {
                        restaurant.kitchen_departments.forEach(({ id, time }) => newRestaurant[id] = time);
                    }

                    if (this.modeId === 'compareOtherPeriod') {
                        newRestaurant.title = daterangeText(this.compareDaterange);
                    }

                    return newRestaurant;
                })
            },
            columns() {
                let columns = [
                    {
                        id: "title",
                        title: this.modeId === "base" ? "Ресторан" : "Период",
                        alignment: "left",
                    },
                    {
                        id: "bar",
                        title: "Бар",
                        alignment: "right",
                        filter: "durationFormat",
                        difference: {
                            good: "negative"
                        },
                    },
                    {
                        id: "time_pay",
                        title: "Время оплаты",
                        alignment: "right",
                        filter: "durationFormat",
                        difference: {
                            good: "negative"
                        },
                    },
                    {
                        id: "time_order",
                        title: "Общее время",
                        alignment: "right",
                        filter: "durationFormat",
                        difference: {
                            good: "negative"
                        },
                    },
                ];

                let dynamicColumns = [];

                if (this.groupKitchenDepartments) {
                    dynamicColumns.push({
                        id: "kitchen",
                        title: "Кухня",
                    });
                } else {
                    dynamicColumns = this.reportTotal?.kitchen_departments?.map(({ id, title }) => ({ id, title }));
                }

                dynamicColumns = dynamicColumns.map(dynamicColumn => ({
                    ...dynamicColumn,
                    alignment: "right",
                    filter: "durationFormat",
                    difference: {
                        good: "negative"
                    },
                }));

                columns.splice(2, 0, ...dynamicColumns);

                return columns;
            }
        },
        methods: {
            ...mapMutations([
                "setTimingHallSorting",
            ]),
            ...mapActions([
                "requestHallTimings"
            ]),
            findCompareRestaurant(compareRestaurants, reportRestaurant) {
                let compareRestaurant =  compareRestaurants?.find(({ id }) =>id === reportRestaurant.id);

                if (typeof compareRestaurant === "undefined") {
                    return {
                        title: daterangeText(this.compareDaterange)
                    }
                }

                return compareRestaurant;
            },
            showReport() {
                this.status = "success";
            },
            showError() {
                this.status = "error";
            },
            loadHallTimings() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[`${daterangeKey(daterange)}-${this.reportRestaurantsKey}`])
                );

                this.requestHallTimings({
                    dateranges: daterangesWithoutData,
                    restaurants: this.reportRestaurants,
                    restaurantsKey: this.reportRestaurantsKey,
                }).then(this.showReport, this.showError);
            },
        },
        created() {
            if (this.userRestaurantsList?.length === 1) {
                this.$router.push({
                    name: "TimingHallRestaurant",
                    params:{
                        id: this.userRestaurantsList?.[0]?.id
                    }
                });
            } else {
                if (this.reportRestaurants?.length === 0) {
                    this.status = "no-restaurants";
                } else if (this.requiredHallTimings?.every(Boolean)) {
                    this.showReport();
                } else {
                    this.loadHallTimings();
                }
            }
        },
        watch: {
            requiredHallTimings(requiredHallTimings) {
                if (this.reportRestaurants?.length === 0) {
                    this.status = "no-restaurants";
                } else if (!requiredHallTimings?.every(Boolean)) {
                    this.loadHallTimings();
                } else {
                    this.showReport();
                }
            }
        },
    }
</script>

<style lang="scss">
    .timing-hall-total__settings {
        margin-bottom: 15px;
    }
    .timing-hall-total__data {
        display: grid;
        grid-gap: 16px;
    }
</style>
